import React from "react";
import { graphql, Link } from "gatsby";
import { css } from "@emotion/react";

import Layout from "../components/layout";
import Header from "../components/Header";

export default function HowToPage({ data, pageContext, location }) {
  const post = data.markdownRemark;
  const { previous, next } = pageContext;

  return (
    <Layout
      preTitle={post.frontmatter.title}
      description={post.excerpt}
      image={post.frontmatter.image && post.frontmatter.image.publicURL}
      pathname={location.pathname}
    >
      <Header />
      <div
        css={css`
          max-width: 42.5rem;
          margin: auto;
          padding: 0 1rem 5rem 1rem;

          li::marker {
            font-weight: bold;
          }

          figure {
            margin: 0;
          }

          figcaption {
            text-align: center;
            font-size: 0.9rem;
            font-weight: lighter;
          }
        `}
      >
        {post.frontmatter.title && <h1>{post.frontmatter.title}</h1>}
        {post.frontmatter.date && (
          <p
            css={css`
              font-style: italic;
            `}
          >{`Last updated ${post.frontmatter.date} - ${post.timeToRead} min read`}</p>
        )}
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <ul
          css={css`
            display: flex;
            justify-content: space-between;
            list-style: none;
            margin: auto;
            padding: 0;
          `}
        >
          <li>
            {previous && (
              <Link
                to={previous.fields.slug}
                rel="prev"
                css={css`
                  padding-right: 1.5rem;
                  float: left;
                `}
              >
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link
                to={next.fields.slug}
                rel="next"
                css={css`
                  padding-left: 1.5rem;
                  float: right;
                  text-align: right;
                `}
              >
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
        <p>
          <Link to="/how-to/">← More how to articles</Link>
        </p>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD")
        image {
          publicURL
        }
      }
      excerpt(pruneLength: 160)
    }
  }
`;
